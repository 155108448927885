import styled from "styled-components";

export const Container = styled.div`
  position: relative;
  margin-bottom: 250px; 
  @media only screen and (max-width: 1300px) {
    margin-bottom: 300px;
  }
  @media only screen and (max-width: 1000px) {
    margin-bottom: 400px;
  }


  @media only screen and (max-width: 768px) {
    margin-bottom: 325px;
  }
`;

export const SliderContainer = styled.div`
  position: absolute;
  top: 200px;
  width: 100%;
  padding-left: ${({ theme }) =>
    theme.layout.content.paddingHorizontal.desktop}px;
  padding-right: ${({ theme }) =>
    theme.layout.content.paddingHorizontal.desktop}px;

  @media only screen and (max-width: 768px) {
    padding-left: ${({ theme }) =>
    theme.layout.content.paddingHorizontal.ipad}px;
    padding-right: ${({ theme }) =>
    theme.layout.content.paddingHorizontal.ipad}px;
  }
  @media only screen and (max-width: 560px) {
    padding-left: 18px;
    padding-right: 18px;
    top: 100px;
  }
`;

export const TitleWrapper = styled.div`
  background-color: #ffffffaa;
  width: calc(
    100% +
      ${({ theme }) =>
    theme.layout.content.paddingHorizontal.desktop * 2 + 128}px
  );
  transform: translateX(
    -${({ theme }) => theme.layout.content.paddingHorizontal.desktop + 64}px
  );
  @media only screen and (max-width: 768px) {
    width: 100%;
    transform: none;
    margin-bottom: 30px;
  }
`;

export const SliderContent = styled.div`
  border: 4px solid ${({ theme }) => theme.colors.darkGray2};
  padding: 20px 60px;

  @media only screen and (max-width: 768px) {
    padding: 20px 30px;
  }
`;
