export default [
  {
    id: 1,
    name: "Jessica L",
    date: "Aug 16, 2018",
    location: "Phoenix",
    shortSummary: "My closet looks AMAZING",
    content:
      "Classy Kitchen and Bath completed my kitchen over two years ago and I still absolutely love it! The quality of the materials and installation is top-notch. I love the custom elements that they designed just for my home's layout and I love the soft close drawers that keep things from getting banged up. Two years in and my white and gray kitchen still looks brand new.",
  },
  {
    id: 2,
    name: "Sara V",
    date: "Jan 9, 2019",
    location: "Gilbert",
    shortSummary: "I could not be happier",
    content:
      "Cristin was very easy to work with. She gave me excellent advice on what I could do to stay within my budget. She also gave me very good advice on what would look nice. Very efficient use of time. I am very happy with end result.",
  },
  {
    id: 3,
    name: "Diane S",
    date: "Oct 10, 2019",
    location: "Chandler",
    shortSummary: "Professional all the way around",
    content:
      "Brittany Douglas is awesome. Very helpful with closet and kitchen counter quartz. Knows her stuff!",
  },
  {
    id: 4,
    name: "Dave N",
    date: "Jul 25, 2018",
    location: "Venice CA",
    shortSummary: "Extremely knowledgeable",
    content:
      "We have had several projects done by this company and each is beautiful. Our designer, Azucena Vidal Varpilah is very professional and efficient. We recently had our kitchen cabinets and island refaced and a new backsplash put on. Azucena and my father worked together and secretly had it done while we were out of the country for two months this summer. We came home and were absolutely flabbergasted! We have a completely new kitchen that is stunning. Thank you for your professionalism and attention to detail.",
  },
  {
    id: 5,
    name: "Karyn C",
    date: "Jun 29, 2016",
    location: "Carlsbad CA",
    shortSummary: "Crew was very efficient",
    content:
      "Josh Ream at Classy Closets is one of the most kind and giving people I've ever met. He was truly a joy to work with and extremely personable. He bent over backwards to help us make decisions about our kitchen remodel, giving us suggestions and helping us decide what was best for our home. I look forward to working with Josh and Classy Closets again in the future. Thanks, Josh!",
  },
  {
    id: 6,
    name: "Laura F",
    date: "Oct 7, 2011",
    location: "Gilbert",
    shortSummary: "Designers were super informative and helpful",
    content:
      "This is the third project they have done for us. We have replaced cabinets in the kitchen, a complete bathroom remodel with a new shower, tile, and cabinets, and all the closets in our home. Each project turned out very nice. The showroom in Chandler was also very nice and it was good to be able to see kitchen cabinets and the countertops in the showroom displays before they were installed. They said their cabinets and doors are all built here locally, I think in Chandler.",
  },
]
